import React, { useEffect, useMemo } from 'react';

const InstagramFeed = () => {
    // Memoize the Instagram post URLs
    const postUrls = useMemo(() => [
        'https://www.instagram.com/p/DCl79OXIGui/',
        'https://www.instagram.com/p/DClSlCcNPNS/',
        'https://www.instagram.com/p/DCj5RvLtzbe/'
    ], []);

    useEffect(() => {
        console.log('Instagram Embed Script:', window.instgrm);
        if (window.instgrm) {
            window.instgrm.Embeds.process();
        }
    }, [postUrls]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            let size;
            if (width < 640) {
                size = 'sm';
            } else if (width < 768) {
                size = 'md';
            } else if (width < 1024) {
                size = 'lg';
            } else if (width < 1280) {
                size = 'xl';
            } else {
                size = '2xl';
            }
            console.log('Screen size:', size);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call handler right away so we get size on load

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="bg-gray-800 py-8">
            <div className="container mx-auto px-12 lg:flex items-center justify-center">
                <h2 className="text-white font-extrabold pt-6 pb-5 lg:pr-10 w-full text-4xl md:text-5xl lg:w-1/3 text-center">
                    AI Art On Instagram
                </h2>
                <div className="space-y-6 lg:w-[500px]">
                    {postUrls.map((url, index) => (
                        <div
                            key={index}
                            className="instagram-post"
                            dangerouslySetInnerHTML={{
                                __html: `
                                    <blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14" style="max-width: 100%; width: 100%; margin: auto;">
                                    </blockquote>
                                `,
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InstagramFeed;