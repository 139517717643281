import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const TwitterFeed = () => {
    //Twitter tweet IDs
    const tweetIds = [
        "1860033319142064165",
        "1859571923966919165",
        "1858502357765558552",
    ];

    return (
        <div className="bg-gray-700 py-8 flex flex-col items-center justify-center">
            <h2 className="text-white font-extrabold pt-6 pb-5 text-6xl flex justify-center">
                Social Media
            </h2>
            <div className="container px-4 flex flex-col xl:flex-row items-center justify-center">
                <h2 className="text-white font-extrabold pt-6 text-4xl md:text-5xl text-center xl:text-left xl:mr-8 xl:w-1/3">
                    Get Featured on X
                </h2>
                <div className="pt-6 w-full lg:w-[600px] xl:w-[500px] flex flex-col items-center">
                    {tweetIds.map((id) => (
                        <div key={id} className="flex justify-center w-full">
                            <div className="w-full max-w-lg shadow-lg rounded-lg px-5 overflow-hidden">
                                <TwitterTweetEmbed tweetId={id} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TwitterFeed;
