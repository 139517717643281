import Navbar from "../components/Navbar";
import React, { useState } from 'react';
// import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

// const fetchPhotosFromFirebase = async () => {
//     const storage = getStorage();
//     const listRef = ref(storage, 'photos/'); // Adjust the path as needed
//     const photos = [];

//     try {
//         const res = await listAll(listRef);
//         for (const itemRef of res.items) {
//             const url = await getDownloadURL(itemRef);
//             photos.push(url);
//         }
//     } catch (error) {
//         console.error("Error fetching photos from Firebase Storage:", error);
//     }

//     return photos;
// };

const Quiz = () => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);

    const handleAnswerClick = (answer) => {
        setSelectedAnswer(answer);
        // Assuming 'photo1' is the correct answer for demonstration purposes
        setIsCorrect(answer === 'photo1');
    };

    return (
        <div>
            <Navbar />
            <h1 className="text-6xl text-white font-extrabold mb-8">Quiz</h1>
            <div className="flex space-x-4">
                <img 
                    src={require("../assets/images/cumulus.jpg")}
                    alt="1" 
                    onClick={() => handleAnswerClick('photo1')} 
                    className={`cursor-pointer ${selectedAnswer === 'photo1' ? 'border-4 border-blue-500' : 'border-2 border-gray-300'}`}
                />
                <img 
                    src={require("../assets/images/cumulonimbus.jpg")}
                    alt="2" 
                    onClick={() => handleAnswerClick('photo2')} 
                    className={`cursor-pointer ${selectedAnswer === 'photo2' ? 'border-4 border-blue-500' : 'border-2 border-gray-300'}`}
                />
            </div>
            {isCorrect !== null && (
                <div className="mt-4 text-xl">
                    {isCorrect ? 'Correct!' : 'Incorrect, try again.'}
                </div>
            )}
        </div>
    );
};

export default Quiz;