import React from "react";


const About = () => {
    return (
        <div className="w-full px-10 bg-gray-900 py-[75px] lg:py-[200px] xl:py-[200px] 2xl:py-[200px] sm:space-x-14">

            <div className="w-full lg:items-center lg:justify-center xl:flex xl:gap-x-10">

                <div className="w-full md:w-2/3 lg:w-2/3 xl:w-2/3 pt-5 mx-auto">
                    <h2 className="text-white font-extrabold pt-6 text-4xl md:text-5xl lg:text-6xl sm:text-4xl">
                        What is Cloud Point?
                    </h2>
                    <p className="text-white pt-5 text-lg">What is the point of clouds? We at Cloud Point see them as the most amazing by-products of nature - they don't need to be there! A mere gathering of water droplets formed from atmospheric processes can give rise to some of the most amazing shapes, forms and colours. But what we love about them most is they are ever changing. You will never see the same cloud twice and they are always different from one moment to the next. But thats what makes them special; impermanence.</p>
                    <p className="text-white pt-5 text-lg">Cloud Point gives you the opportunity to capture these moments, learn what clouds they are, and share this with others. Our mission is to create a platform where you can turn to appreciate nature in its ever-changing form, whilst contributing to science and hopefully learning something along the way.</p>
                    <p className="text-white pt-5 text-lg">Think also; what connects us to the world gone before our time? These days there are so few things that link us now. But when you consider it, you're looking at exactly the same forms and shapes your ancestors would have seen.</p>
                    <p className="text-white pt-5 text-lg"> Clouds give us the calming, visual opportunity to pause and reflect. So next time you see a cloud, pause for a second and give thanks for all that has been, and is, and will be, under this incredible sky.</p>


                    <p className="text-yellow-400 py-2 text-3xl font-bold">So join our like-minded community today, <span className="bg-gray-400 p-1 md:p-2 lg:p-2 xl:p-2 2xl:p-2">for free.</span></p>

                </div>
                {/* <div class="relative bg-gray-600"> */}
                    <div className="relative flex items-center justify-center pt-5">
                        <div className="border border-gray-400 rounded-full bg-white relative z-20 h-[500px] w-[250px] flex items-center justify-center mx-auto">
                            <img className="py-6 w-full sm:w-[250px]"
                                src={require("../assets/images/phone_3.png")} alt="Phone Example" height={750} width={250}
                            />
                        </div>
                        {/* <div className="absolute -inset-1 blur-md bg-gradient-to-br from-pink-500 via-cyan-500 to-violet-500 z-10"></div> */}
                    </div>
                {/* </div> */}

                {/* <img className="h-[250px] rounded-xl"
                    src={require("../assets/images/author.jpg")} alt="Laurence Wayne"
                /> */}
            </div>
        </div>
    )
}

export default About